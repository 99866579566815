import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { Form, Button, Col, Row } from "antd";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import {
  DesktopOutlined,
  ProductOutlined,
  UnorderedListOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import useGet from "../../../global/hooks/useGet";
import { useEffect } from "react";
import { showToast } from "../../../global/utils/toast";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import OrderItemIcon from "../../../assets/images/vectors/SideBarIcons/OrderItemIcon";

export const OrderItemForm = ({ id }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const url = `${process.env.REACT_APP_API_URL}/api/orderitem/${id}`;
  const { data, loading, error } = useGet(url);

  useEffect(() => {
    if (!id || loading) return;
    const getOrderItemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            CustomerNo: data?.data?.CustomerName,
            LicenseName: data?.data?.LicenseInfo?.LicenseName,
            OldModel: data?.data?.LicenseInfo?.OldModelName ?? "",
            Model: data?.data?.LicenseInfo?.ModelName ?? "",
            OldType: data?.data?.LicenseInfo?.OldType,
            Type: data?.data?.LicenseInfo?.Type,
            OptionNames: data?.data?.LicenseInfo?.OptionNames ?? "",
            Camera: data?.data?.LicenseInfo?.Camera ?? 0,
            Channel: data?.data?.LicenseInfo?.Channel ?? 0,
            Lockset: data?.data?.LicenseInfo?.Lockset ?? 0,
            Facegate: data?.data?.LicenseInfo?.Facegate ?? 0,
            Subnode: data?.data?.LicenseInfo?.Subnode ?? 0,
            TrilogyLockset: data?.data?.LicenseInfo?.ContLock ?? 0,
            Intercom: data?.data?.LicenseInfo?.Intercom ?? 0,
            Sicucom: data?.data?.LicenseInfo?.Sicucom ?? 0,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          showToast("Error fetching data!", "error", faWarning);
        }
      }
    };

    getOrderItemInfoById();
  }, [id, data, loading]);

  const breadcrumbItems = [
    {
      label: "Order Item",
      href: "/orderitemlist",
    },
    {
      label: "Information",
      href: `/orderiteminfo/${id}`,
    },
  ];

  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/orderitemlist");
      },
    },
  ];
  return (
    <div>
      {/* <div className="flex justify-between">
        <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
        <CommonButton buttonsData={buttonsData} />
      </div> */}
      {!loading && (
        <Form form={form}>
          <div className="mt-8 bg-white overflow-hidden">
            <div className="border rounded-md">
              <div className="bg-[#EEEEEF] p-2 flex items-center">
                <InfoCircleOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  Upgrade Information
                </span>
              </div>
              <div className="p-2">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Old Model"
                      name="OldModel"
                      disable={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Model" name="Model" disable={true} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Old Type"
                      name="OldType"
                      disable={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Type" name="Type" disable={true} />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="border mt-2 rounded-md">
              <div className="bg-[#EEEEEF] p-2 flex items-center">
                <SettingOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  License Options
                </span>
              </div>

              <Row gutter={[16, 16]} className="p-2">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <CustomInput
                    label="Options"
                    name="OptionNames"
                    disable={true}
                  />
                </Col>
              </Row>
            </div>
            <div className="border mt-2 rounded-md">
              <div className="bg-[#EEEEEF] p-2 flex items-center">
                <DesktopOutlined className="text-[20px]" />
                <span className="text-[16px] text-[#414141] font-semibold ml-2">
                  License Devices
                </span>
              </div>
              <div className="p-2">
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Camera" name="Camera" disable={true} />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Channel"
                      name="Channel"
                      disable={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Lockset"
                      name="Lockset"
                      disable={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Facegate"
                      name="Facegate"
                      disable={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Subnode"
                      name="Subnode"
                      disable={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Trilogy Lockset"
                      name="TrilogyLockset"
                      disable={true}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Intercom"
                      name="Intercom"
                      disable={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Sicunet Intercom"
                      name="Sicucom"
                      disable={true}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};
