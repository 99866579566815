import { Navigate, useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import DeviceIcon from "../../assets/images/vectors/SideBarIcons/DeviceIcon";
import DeviceLogIcon from "../../assets/images/vectors/SideBarIcons/DeviceLogIcon";
import OrderIcon from "../../assets/images/vectors/SideBarIcons/OrderIcon";
import OrderItemIcon from "../../assets/images/vectors/SideBarIcons/OrderItemIcon";
import LicenseIcon from "../../assets/images/vectors/SideBarIcons/LicenseIcon";
import LicenseOpIcon from "../../assets/images/vectors/SideBarIcons/LicenseOpIcon";
import AccountIcon from "../../assets/images/vectors/SideBarIcons/AccountIcon";
import DealerIcon from "../../assets/images/vectors/SideBarIcons/DealerIcon";
import OemIcon from "../../assets/images/vectors/SideBarIcons/OemIcon";
import OemInfoIcon from "../../assets/images/vectors/SideBarIcons/OemInfoIcon";

// icon handler function
const iconHandler = (icon) => <div>{icon}</div>;

const allMenuItems = {
  SuperAdmin: [
    {
      label: "Device",
      key: "/devicelist",
      icon: <DeviceIcon />,
    },
    {
      label: "Device Log",
      key: "/deviceloglist",
      icon: <DeviceLogIcon />,
    },
    {
      label: "Order",
      key: "/orderlist",
      icon: <OrderIcon />,
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: <OrderItemIcon />,
    },
    {
      label: "License",
      key: "/licenselist",
      icon: <LicenseIcon />,
    },
    {
      label: "License Option",
      key: "/licenseoptionlist",
      icon: <LicenseOpIcon />,
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: <AccountIcon />,
    },
    {
      label: "Dealer",
      key: "/dealerlist",
      icon: <DealerIcon />,
    },
    {
      label: "OEM",
      key: "/oemlist",
      icon: <OemIcon />,
    },
    {
      label: "OEM Info",
      key: "/oeminfolist",
      icon: <OemInfoIcon />,
    },
    //   {
    //     label: "Users",
    //     key: "/users",
    //     icon: <TeamOutlined />,
    //     children: [
    //       {
    //         label: "Employees",
    //         key: "/employee",
    //         icon: <UserOutlined />,
    //       },
    //     ],
    //   },
  ],
  OemAdmin: [
    {
      label: "Order",
      key: "/orderlist",
      icon: <OrderIcon />,
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: <OrderItemIcon />,
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: <AccountIcon />,
    },
    {
      label: "Dealer",
      key: "/dealerlist",
      icon: <DealerIcon />,
    },
  ],
  OemUser: [
    {
      label: "Order",
      key: "/orderlist",
      icon: <OrderIcon />,
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: <OrderItemIcon />,
    },
  ],
  DealerAdmin: [
    {
      label: "Order",
      key: "/orderlist",
      icon: <OrderIcon />,
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: <OrderItemIcon />,
    },
    {
      label: "Account",
      key: "/accountlist",
      icon: <AccountIcon />,
    },
  ],
  DealerUser: [
    {
      label: "Order",
      key: "/orderlist",
      icon: <OrderIcon />,
    },
    {
      label: "Order Item",
      key: "/orderitemlist",
      icon: <OrderItemIcon />,
    },
  ],
};

const allProtectedRouteList = {
  SuperAdmin: [
    "/profile",
    "/oemlist",
    "/oem/add",
    "devicelist/add",
    "/devicelog/info/",
    "/device/edit",
    "/device/info",
    "/accountlist",
    "/account/add",
    "/account/edit",
    "/account/info",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/devicelist",
    "/deviceloglist",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo/edit",
    "/orderinfo/info",
    "/orderitemlist",
    "/orderitem/add/",
    "orderiteminfo",
    "/licenselist",
    "/license/add",
    "/license/edit",
    "/license/info",
    "/licenseoptionlist",
    "/licenseoption/add",
    "/licenseoption/info",
    "/licenseoption/edit",
    "/licenseoption",
    "/oeminfolist",
    "/oeminfo/add",
    "/oem/edit",
    "/oem/info",
    "/oeminfo/edit",
    "/oeminfo/info",
    "/addDeviceByList",
    "/addDeviceByCount",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  OemAdmin: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/dealerlist",
    "/dealer/add",
    "/dealer/edit",
    "/dealer/info",
    "/orderlist",
    "/orderinfo",
    "/orderitemlist",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/info",
  ],
  OemUser: [
    "/profile",
    "/orderlist",
    "/orderinfo",
    "/orderitemlist",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/info",
  ],
  DealerAdmin: [
    "/profile",
    "/accountlist",
    "/account/edit",
    "/account/info",
    "/account/add",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  DealerUser: [
    "/profile",
    "/orderlist",
    "/order/add",
    "/orderedit",
    "/orderinfo",
    "/orderitemlist",
    "/orderitem/add/",
    "/orderinfo/edit",
    "/orderinfo/info",
    "orderiteminfo",
    "/orderitem/edit",
    "/orderitem/info",
  ],
  Customer: [],
};

export const PrivateRoute = ({ children }) => {
  // const user = useAuth();
  const user = JSON.parse(sessionStorage.getItem("userData")); // need to change later
  // console.log(user);
  const { pathname } = useLocation();

  // check current route is permitted or not
  const isPermittedRoute =
    user?.role_name &&
    allProtectedRouteList[user.role_name]?.some((item) =>
      pathname?.includes(item)
    );

  // console.log({ user, isPermittedRoute });

  return user && isPermittedRoute ? (
    <SideBar items={allMenuItems[user?.role_name]}>{children} </SideBar>
  ) : (
    <Navigate to={"/"} />
  );
};
