import React from "react";
const OrderIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      fillRule="evenodd"
      d="M8.72 2.735a1.667 1.667 0 0 1 2.47-.1l.09.1 3.277 3.932h2.487a1.242 1.242 0 0 1 1.237 1.379l-.077.638-.083.617-.069.461-.079.496-.09.521-.101.54-.114.551c-.06.278-.124.555-.193.833-.184.731-.416 1.45-.693 2.152l-.184.45-.179.407-.168.361-.079.162-.207.408a1.61 1.61 0 0 1-1.303.852l-.133.005H5.462a1.608 1.608 0 0 1-1.425-.84l-.193-.375-.15-.308-.079-.171-.167-.374a17.92 17.92 0 0 1-1.053-3.275l-.118-.54-.106-.529-.094-.51-.083-.49-.072-.461-.062-.428-.075-.569-.055-.463-.014-.128a1.242 1.242 0 0 1 1.123-1.367l.113-.005h2.491L8.72 2.735Zm-.42 7.867a.833.833 0 0 0-1.633.271l.011.097.417 2.5.02.095a.834.834 0 0 0 1.634-.272l-.01-.096-.417-2.5-.021-.095Zm4.337-.59a.833.833 0 0 0-.938.59l-.02.095-.417 2.5a.834.834 0 0 0 1.622.368l.021-.095.417-2.5a.833.833 0 0 0-.685-.958ZM10 3.802 7.612 6.667h4.775L10 3.802Z"
      clipRule="evenodd"
    />
  </svg>
);
export default OrderIcon;
