import React from "react";
const DealerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M17 9.75V9.5C17 8.125 15.875 7 14.5 7h-5v2.75c0 .719-.563 1.25-1.25 1.25C7.531 11 7 10.469 7 9.75V6a2.243 2.243 0 0 1 2.25-2h3.5c.063 0 .156.031.25.031l1.25-1.25a1.735 1.735 0 0 1 2.469 0l2.437 2.438a1.735 1.735 0 0 1 0 2.468L17 9.876V9.75ZM10.5 8v.031h3.75c.938 0 1.719.781 1.75 1.719v.031c-.031.844-.625 1.563-1.438 1.719.282.25.438.625.438 1.031 0 .813-.688 1.5-1.5 1.5h-.094c.063.156.094.313.094.5 0 .813-.688 1.5-1.5 1.5H7c-.031 0-.063-.031-.094-.031L5.72 17.188a1.735 1.735 0 0 1-2.469 0L.812 14.75a1.735 1.735 0 0 1 0-2.469L3.095 10l.531-1.813A2.962 2.962 0 0 1 6 6.063V9.75C6 11 7 12 8.25 12c1.219 0 2.25-1 2.25-2.25V8Z"
    />
  </svg>
);
export default DealerIcon;
