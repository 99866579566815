import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../utils/toast";
import AuthLayout from "./AuthLayout";
// import "../styles/login.css";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import usePostLogin from "../hooks/usePostLogin";
import UserIcon from "../../assets/images/vectors/UserIcon";
import KeyIcon from "../../assets/images/vectors/KeyIcon";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const Signup = () => {
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/signup`;
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  localStorage.setItem("oemNo", oemNo);
  oemData && localStorage.setItem("oemData", JSON.stringify(oemData));
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();
  const location = useLocation();
  // Extract the token from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (postData) {
      showToast("Account Created Successfully!", "success", faCheckCircle);
      navigate("/");
    }
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const modValues = { ...values, token };
      await postAction(modValues);
    } catch (error) {
      showToast(
        "Login failed. Please check your credentials and try again.",
        "error",
        faWarning
      );
    }
  };

  return oemDataLoading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <AuthLayout>
      <div className={`w-[336px]`}>
        <img
          // src={`oem/${oemNo}/images/mainLogo.png`}
          // src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
          alt={oemData?.name}
          className="mb-6 mx-auto"
        />
        <>
          <p className="my-8 text-2xl text-center">Welcome Back!</p>
          <Form
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
            onFinish={onSubmitHandler}
          >
            <Form.Item
              label="User Name"
              name="AccountName"
              className="my-input"
              rules={[
                {
                  required: true,
                  message: "Please input your User Name!",
                },
              ]}
            >
              <Input
                className="h-[40px] !bg-white"
                placeholder=" Enter username"
                prefix={<UserIcon />}
              />
            </Form.Item>

            <Form.Item
              label="User Id"
              name="UserId"
              className="my-input"
              rules={[
                {
                  required: true,
                  message: "Please input your User Id!",
                },
              ]}
            >
              <Input
                className="h-[40px] !bg-white"
                placeholder=" Enter user id"
                prefix={<UserIcon />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              className="my-input"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="h-[40px] !bg-white"
                placeholder=" Enter password"
                prefix={<KeyIcon />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="w-full bg-customColor text-white hover:!bg-white hover:!text-customColor border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
              >
                {postLoading ? (
                  <span>
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      size="default"
                      className="mr-2"
                    />
                    Signup
                  </span>
                ) : (
                  "Signup"
                )}
              </Button>
            </Form.Item>
          </Form>
        </>
      </div>
    </AuthLayout>
  );
};

export default Signup;
