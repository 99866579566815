import React from "react";
const SubnodeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.667 13H9c-1.885 0-2.829 0-3.414-.586C5 11.829 5 10.885 5 9V7.667m0 0V5.333m0 2.334h3M11.667 6v3.333m1.667-1.666H10M9.667 13c0-.785 0-1.179.234-1.423.235-.244.613-.244 1.366-.244h.8c.754 0 1.131 0 1.365.244.235.244.235.638.235 1.423s0 1.179-.235 1.423c-.233.244-.611.244-1.365.244h-.8c-.754 0-1.131 0-1.365-.244-.235-.244-.235-.638-.235-1.423ZM3.857 1.333h2.286c1.384 0 1.524.74 1.524 2s-.14 2-1.524 2H3.858c-1.385 0-1.525-.74-1.525-2s.141-2 1.524-2Z"
    />
  </svg>
);
export default SubnodeIcon;
