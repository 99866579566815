import React from "react";
const OemIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#8A8B91"
      fillRule="evenodd"
      d="M9.332 2.156a1.375 1.375 0 0 1 1.335 0l6 3.333c.437.243.708.703.708 1.203v6.616c0 .5-.27.96-.707 1.203l-6 3.333a1.375 1.375 0 0 1-1.335 0l-6-3.333a1.375 1.375 0 0 1-.708-1.203V6.692c0-.5.27-.96.708-1.203l6-3.333Zm.729 1.093a.125.125 0 0 0-.122 0l-6 3.333a.125.125 0 0 0-.064.109v6.618c0 .045.025.087.064.109l6 3.333a.125.125 0 0 0 .122 0l6-3.334a.125.125 0 0 0 .064-.108V6.692a.126.126 0 0 0-.064-.11l-6-3.333Z"
      clipRule="evenodd"
    />
    <path
      fill="#8A8B91"
      fillRule="evenodd"
      d="M6.042 10a3.958 3.958 0 1 1 7.916 0 3.958 3.958 0 0 1-7.917 0ZM10 7.292a2.708 2.708 0 1 0 0 5.416 2.708 2.708 0 0 0 0-5.416Z"
      clipRule="evenodd"
    />
  </svg>
);
export default OemIcon;
