import { useNavigate } from "react-router-dom";
import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "../styles/breadcrumbs.css";
import Home from "../../assets/images/vectors/HomeIcon";

const CommonBreadcrumb = ({ breadcrumbItems }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { role_name } = userData;

  const handleClick = () => {
    if (role_name == "SuperAdmin") {
      navigate("/devicelist");
    } else if (
      ["OemAdmin", "OemUser", "DealerAdmin", "DealerUser"].includes(role_name)
    ) {
      navigate("/orderlist");
    } else if (role_name == "Customer") {
      return;
    } else {
      navigate("/");
    }
  };

  return (
    <Breadcrumb
      className="mt-2"
      separator={<span className="font-bold rotate-90 inline-block "> | </span>}
    >
      <Home className="home-icon" onClick={handleClick} />
      <span className="font-bold text-[#000000] rotate-90 inline-block mx-4">
        {" "}
        |{" "}
      </span>
      {breadcrumbItems.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={item.href} className="breadcrumb-link">
            {item.label}
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CommonBreadcrumb;
