import React from "react";
const DeviceLogIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      fillRule="evenodd"
      d="M18.125 13.75V5a1.875 1.875 0 0 0-1.875-1.875H3.75A1.875 1.875 0 0 0 1.875 5v8.75a1.875 1.875 0 0 0 1.875 1.875h12.5a1.875 1.875 0 0 0 1.875-1.875ZM7.5 16.875h5a.624.624 0 1 1 0 1.25h-5a.625.625 0 1 1 0-1.25ZM6.85 7c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h.004c.193 0 .35-.16.35-.357A.354.354 0 0 0 6.854 7H6.85ZM8.6 7c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h4.55c.193 0 .35-.16.35-.357A.354.354 0 0 0 13.15 7H8.6ZM6.85 9.143c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h.004c.193 0 .35-.16.35-.357a.354.354 0 0 0-.35-.357H6.85Zm1.75 0c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h4.55c.193 0 .35-.16.35-.357a.354.354 0 0 0-.35-.357H8.6Zm-1.75 2.143c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h.004c.193 0 .35-.16.35-.357a.354.354 0 0 0-.35-.357H6.85Zm1.75 0c-.193 0-.35.16-.35.357 0 .197.157.357.35.357h4.55c.193 0 .35-.16.35-.357a.354.354 0 0 0-.35-.357H8.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DeviceLogIcon;
