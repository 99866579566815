import React from "react";
const DeviceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M17.5 5.625h-1.25V5a1.875 1.875 0 0 0-1.875-1.875H3.125A1.875 1.875 0 0 0 1.25 5v7.5a1.875 1.875 0 0 0 1.875 1.875h8.75V15a1.875 1.875 0 0 0 1.875 1.875h3.75A1.875 1.875 0 0 0 19.375 15V7.5A1.875 1.875 0 0 0 17.5 5.625ZM18.125 15a.624.624 0 0 1-.625.625h-3.75a.624.624 0 0 1-.625-.625V7.5a.625.625 0 0 1 .625-.625h3.75a.625.625 0 0 1 .625.625V15Zm-7.5 1.25a.624.624 0 0 1-.625.625H6.875a.625.625 0 1 1 0-1.25H10a.624.624 0 0 1 .625.625Zm6.25-7.5a.625.625 0 0 1-.625.625H15a.625.625 0 1 1 0-1.25h1.25a.625.625 0 0 1 .625.625Z"
    />
  </svg>
);
export default DeviceIcon;
