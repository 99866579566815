import React from "react";
const Home = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="var(--text-color)"
      d="M14.167 17.5H5.833a1.667 1.667 0 0 1-1.666-1.667v-7.5L10 2.5l5.833 5.833v7.5a1.666 1.666 0 0 1-1.666 1.667Z"
      opacity={0.16}
    />
    <path
      fill="var(--text-color)"
      d="M16.667 8.333a.833.833 0 1 0-1.667 0h1.667ZM5 8.333a.833.833 0 1 0-1.667 0H5Zm11.91 2.256a.833.833 0 0 0 1.18-1.178l-1.18 1.178ZM10 2.5l.59-.59a.833.833 0 0 0-1.18 0l.59.59ZM1.91 9.41a.833.833 0 1 0 1.18 1.18L1.91 9.41Zm3.923 8.923h8.334v-1.666H5.833v1.666Zm10.834-2.5v-7.5H15v7.5h1.667ZM5 15.833v-7.5H3.333v7.5H5Zm13.09-6.422-7.5-7.5-1.18 1.178 7.5 7.5 1.18-1.178Zm-8.68-7.5-7.5 7.5 1.18 1.178 7.5-7.5-1.18-1.178Zm4.757 16.422a2.5 2.5 0 0 0 2.5-2.5H15a.833.833 0 0 1-.833.834v1.666Zm-8.334-1.666A.833.833 0 0 1 5 15.833H3.333a2.5 2.5 0 0 0 2.5 2.5v-1.666Z"
    />
  </svg>
);
export default Home;
