import React from "react";
const OrderItemIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      fillRule="evenodd"
      d="M8.437 3.487a1.494 1.494 0 0 0-.486.384L5.014 7.395H2.78L2.68 7.4a1.113 1.113 0 0 0-1.007 1.225l.012.114.05.415.067.51.055.384.065.413.075.439.084.458.094.473.106.484a16.06 16.06 0 0 0 .944 2.935l.15.336.07.153.135.276.174.337a1.442 1.442 0 0 0 1.277.752h8.126l.12-.004a1.443 1.443 0 0 0 1.167-.763l.186-.366.07-.145.151-.325.16-.364.165-.403c.242-.614.446-1.241.609-1.88a3.968 3.968 0 0 1-3.16-6.128l-2.38-2.855-.08-.09a1.494 1.494 0 0 0-1.728-.294Zm-1.181 7.009c.155.098.268.25.319.425l.018.086.374 2.24.01.087a.747.747 0 0 1-1.464.244l-.019-.086-.373-2.24-.01-.087a.747.747 0 0 1 1.145-.67Zm3.684.001a.747.747 0 0 1 1.135.755l-.373 2.24-.02.085a.746.746 0 0 1-1.453-.33l.373-2.24.019-.085a.747.747 0 0 1 .32-.425ZM6.958 7.395l2.14-2.568 2.14 2.568h-4.28Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? "#3A3A41"}
      d="M15.274 7.32c.302-.542.453-.812.678-.812.226 0 .376.27.678.811l.078.14c.086.154.129.23.195.282.067.05.15.07.317.107l.152.034c.585.133.878.199.948.423.07.224-.13.457-.53.924l-.103.12c-.113.133-.17.2-.196.282-.025.082-.017.17 0 .347l.016.162c.06.623.09.934-.092 1.072-.182.139-.456.013-1.005-.24l-.142-.065c-.155-.072-.233-.108-.316-.108-.082 0-.16.036-.316.108l-.142.065c-.548.253-.822.379-1.005.24-.182-.138-.152-.45-.092-1.072l.016-.161c.017-.177.026-.266 0-.348-.025-.082-.082-.149-.196-.28l-.103-.122c-.4-.466-.599-.7-.53-.924.07-.224.364-.29.95-.423l.15-.034c.167-.038.25-.057.317-.107.067-.051.11-.128.195-.282l.078-.14Z"
    />
  </svg>
);
export default OrderItemIcon;
