import React from "react";
const LicenseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M1.667 5.185c0-1.013.81-1.852 1.851-1.852h12.963c1.013 0 1.852.84 1.852 1.852H1.667Zm0 .926h16.666v8.333c0 1.042-.839 1.852-1.852 1.852H3.518a1.828 1.828 0 0 1-1.851-1.851V6.11Zm1.851 8.044c0 .174.116.29.29.29h5.874c.173 0 .318-.116.318-.29 0-.868-.694-1.562-1.563-1.562H5.052c-.868 0-1.534.694-1.534 1.562ZM6.76 7.963c-.665 0-1.273.376-1.62.926-.318.579-.318 1.302 0 1.852.347.579.955.926 1.62.926a1.87 1.87 0 0 0 1.592-.926c.318-.55.318-1.273 0-1.852-.348-.55-.955-.926-1.592-.926Zm5.093.463c0 .26.202.463.463.463h3.704a.476.476 0 0 0 .462-.463.498.498 0 0 0-.463-.463h-3.703a.476.476 0 0 0-.463.463Zm0 1.852c0 .26.202.463.463.463h3.704a.476.476 0 0 0 .462-.463.498.498 0 0 0-.463-.463h-3.703a.476.476 0 0 0-.463.463Zm0 1.852c0 .26.202.463.463.463h3.704a.476.476 0 0 0 .462-.463.498.498 0 0 0-.463-.463h-3.703a.476.476 0 0 0-.463.463Z"
    />
  </svg>
);
export default LicenseIcon;
