import React from "react";
const ProductIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.083 7.5h15.834M5.833 5h.008m3.326 0h.007m-7.09 5c0-3.732 0-5.598 1.159-6.757 1.159-1.16 3.025-1.16 6.757-1.16 3.732 0 5.598 0 6.758 1.16 1.159 1.159 1.159 3.025 1.159 6.757 0 3.732 0 5.598-1.16 6.758-1.159 1.159-3.024 1.159-6.757 1.159-3.731 0-5.598 0-6.757-1.16-1.16-1.159-1.16-3.025-1.16-6.757Z"
    />
    <path
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.667 10.833 1.022.881c.43.371.645.556.645.786 0 .23-.215.415-.645.786l-1.022.88m3.333 0h3.334"
    />
  </svg>
);
export default ProductIcon;
