import React from "react";
const TypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#8A8B91"
      fillRule="evenodd"
      d="M2.134 1.067a1.067 1.067 0 0 0-1.067 1.066v11.734a1.067 1.067 0 0 0 1.067 1.066h11.733a1.066 1.066 0 0 0 1.067-1.066V2.133a1.066 1.066 0 0 0-1.067-1.066H2.134Zm0 1.066h11.733v11.734H2.134V2.133ZM4.8 4.267a.533.533 0 0 0-.533.533v6.4a.533.533 0 0 0 .533.533h6.4a.534.534 0 0 0 .534-.533V4.8a.533.533 0 0 0-.534-.533H4.8Zm.534 6.4V5.333h5.333v5.334H5.334Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TypeIcon;
