import React, { useState } from "react";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { User } from "./User";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import "../styles/sidebar.css";
import useOemData from "../hooks/useOemData";
import { Spinner } from "./Spinner";

//Though the name is SideBar, actually it is a Layout component that has a sidebar also!
export const SideBar = ({ children, items }) => {
  const { Header, Content, Footer, Sider } = Layout;
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, colorBgBase, colorBgMask, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { role_name } = userData;

  const handleImageClick = () => {
    if (role_name == "SuperAdmin") {
      navigate("/devicelist");
    } else if (
      ["OemAdmin", "OemUser", "DealerAdmin", "DealerUser"].includes(role_name)
    ) {
      navigate("/orderlist");
    } else {
      navigate("/");
    }
  };

  return oemDataLoading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          height: "64px",
          padding: "0 0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid rgba(212, 212, 212, 1)`,
        }}
      >
        <div
          style={{
            cursor: "pointer",
            marginLeft: "16px",
          }}
          onClick={handleImageClick}
        >
          <img
            src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            alt="Logo"
          />
        </div>
        <div className="mr-4">
          <User />
        </div>
      </Header>
      <Layout style={{ backgroundColor: "white" }}>
        <Sider
          // collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            backgroundColor: "#fff",
            height: "94vh",
          }}
        >
          <div className="" />
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items?.map((item) => ({
              ...item,
              icon: React.cloneElement(item.icon, {
                color: item.key === selectedKey ? "#ffffff" : "#3A3A41",
              }),
            }))}
            onClick={(item) => {
              navigate(item?.key);
              setSelectedKey(item.key);
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 20,
              right: "-24px",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <div className="bg-[#f1f1f1] p-[4px] rounded-full border border-white  focus:!bg-none">
                <FaAngleRight color="#4B5563" size={20} />
              </div>
            ) : (
              <div className="bg-[#f1f1f1] p-[4px] rounded-full border border-white  focus:!bg-none">
                <FaAngleLeft color="#4B5563" size={20} />
              </div>
            )}
          </div>
        </Sider>
        <Layout style={{ backgroundColor: "#F2F2F2" }}>
          <Content style={{ margin: "16px 22px" }}>
            <div>{children}</div>
          </Content>
          {/* <Footer style={{ textAlign: "center" }}>
              Copyright ©{new Date().getFullYear()} Sicunet. All rights
              reserved.
            </Footer> */}
        </Layout>
      </Layout>
    </Layout>
  );
};
