import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Spinner } from "./global/components/Spinner";
import { protectedRoutes } from "./routes";
import { PageNotFound } from "./global/components/PageNotFound";
import Login from "./global/auth/Login";
import { PrivateRoute } from "./global/components/PrivateRoute";
import { ConfigProvider } from "antd";
import { ForgotPassword } from "./global/auth/ForgotPassword";
import { ResetPassword } from "./global/auth/ResetPassword";
import { CustomerLogin } from "./global/auth/CustomerLogin";
import { OrderedItem } from "./modules/UpgradeLicense/Page/OrderedItem";
import { OrderedItemForm } from "./modules/UpgradeLicense/Components/OrderedItemForm";
import AutoLogout from "./global/auth/AutoLogout";
import InitialLicense from "./modules/License/Page/InitialLicense";
import UpgradedLicense from "./modules/UpgradeLicense/Page/UpgaredLicense";
import useOemData from "./global/hooks/useOemData";
import Signup from "./global/auth/Signup";

function App() {
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();

  function hexToRGBA(hex, opacity) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  useEffect(() => {
    document.title = oemData && `License | ${oemData?.name}`;
    const favicon = document.getElementById("favicon");
    const faviconPath = oemNo && require(`./oem/${oemNo}/favicon.ico`);
    if (faviconPath) {
      favicon.href = faviconPath;
    }
  }, [oemNo, oemData]);

  useEffect(() => {
    const oemColor = oemData?.color;
    const oemColorOpacity = oemData?.color && hexToRGBA(oemData?.color, 0.1);
    document.documentElement.style.setProperty("--text-color", oemColor);
    document.documentElement.style.setProperty(
      "--text-color-op-10",
      oemColorOpacity
    );
  }, [oemData]);

  // const isAuthenticating = useAuthCheck();
  const isAuthenticating = false;
  return isAuthenticating ? (
    <div className={"h-screen flex justify-center items-center"}>
      <Spinner />
    </div>
  ) : (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: oemData?.color,
            colorPrimaryHover: "white",
          },
        },
        token: {
          fontFamily: '"Poppins", sans-serif',
        },
      }}
    >
      <>
        <Router>
          <AutoLogout timeout={30 * 60 * 1000} />
          <Routes>
            {protectedRoutes.map((route, id) => (
              <Route
                key={id}
                path={route.path}
                element={<PrivateRoute children={route.element} />}
              />
            ))}

            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/initial" element={<CustomerLogin />} />
            <Route
              path="/setInitial"
              element={<InitialLicense type="initial" />}
            />
            <Route
              path="/setUpgrade"
              element={<InitialLicense type="upgrade" />}
            />
            {/* <Route path="/newLicense" element={<LicenseUpgrade type="new" />} />*/}
            <Route path="/newLicense" element={<UpgradedLicense />} />
            <Route path="/orderedItems" element={<OrderedItem />} />
            <Route
              path="/license/information/:id"
              element={<OrderedItemForm />}
            />
            <Route path="/upgrade" element={<CustomerLogin />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </>
    </ConfigProvider>
  );
}
export default App;
