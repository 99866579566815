import React, { createContext, useContext, useState } from "react";

export const LicenseContext = createContext([]);
export const StepperContext = createContext();

export const LicenseProvider = ({ children }) => {
  const [selectedLicense, setSelectedLicense] = useState();

  return (
    <LicenseContext.Provider value={{ selectedLicense, setSelectedLicense }}>
      {children}
    </LicenseContext.Provider>
  );
};

export const StepperProvider = ({ children }) => {
  const [selectedStep, setSelectedStep] = useState(1);

  return (
    <StepperContext.Provider value={{ selectedStep, setSelectedStep }}>
      {children}
    </StepperContext.Provider>
  );
};
