import React from "react";
const MacIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path
        strokeWidth={1.5}
        d="M2.667 8c0-2.514 0-3.771.781-4.552.782-.78 2.038-.781 4.552-.781s3.772 0 4.552.781c.781.781.782 2.038.782 4.552s0 3.771-.782 4.552c-.781.78-2.038.781-4.552.781s-3.771 0-4.552-.781c-.78-.781-.781-2.038-.781-4.552Z"
      />
      <path d="M5.333 1.333v1.334m5.334-1.334v1.334M8 1.333v1.334M5.333 13.333v1.334M8 13.333v1.334m2.667-1.334v1.334m4-4h-1.333M2.667 5.333H1.334m1.333 5.334H1.334M2.667 8H1.334m13.333-2.667h-1.333M14.667 8h-1.333m-8.18 2.845c.489.488 1.275.488 2.846.488.527 0 .965 0 1.334-.018l1.98-1.982c.02-.368.02-.806.02-1.333 0-1.571 0-2.357-.489-2.845-.488-.488-1.274-.488-2.845-.488-1.571 0-2.357 0-2.845.488S4.667 6.429 4.667 8c0 1.571 0 2.357.488 2.845Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MacIcon;
