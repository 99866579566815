import { Button, Col, Input, Row, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import useGet from "../../../global/hooks/useGet";

import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";

export const OrderFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const navigate = useNavigate();
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "items");
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const modifiedDealer = getModifiedResponse(optionData, "dealers");
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div className="">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderNo"
              placeholder="No"
              value={filter.OrderNo}
              onChange={handleFilterChange}
            />
          </Col>
          {loggedInUser.role_name == "SuperAdmin" && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="OEM"
                optionFilterProp="OemNo"
                value={filter.OemNo || undefined}
                onChange={(value) => setFilter({ ...filter, OemNo: value })}
                onSearch={onSearch}
                options={modifiedOems}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {loggedInUser.role_name !== "DealerAdmin" &&
            loggedInUser.role_name !== "DealerUser" && (
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Select
                  showSearch
                  placeholder="Dealer"
                  optionFilterProp="DealerNo"
                  value={filter.DealerNo || undefined}
                  onChange={(value) =>
                    setFilter({ ...filter, DealerNo: value })
                  }
                  onSearch={onSearch}
                  options={modifiedDealer}
                  style={{ width: "100%" }}
                />
              </Col>
            )}
          {loggedInUser.role_name != "DealerUser" && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Account"
                optionFilterProp="AccountNo"
                value={filter.AccountNo || undefined}
                onChange={(value) => setFilter({ ...filter, AccountNo: value })}
                // onSearch={onSearch}
                options={modifiedAccounts}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderName"
              placeholder="Name"
              value={filter.OrderName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Confirmed"
              optionFilterProp="Paid"
              value={filter.Paid || undefined}
              onChange={(value) => setFilter({ ...filter, Paid: value })}
              onSearch={onSearch}
              options={[
                {
                  value: "1",
                  label: "Yes",
                },
                {
                  value: "0",
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            {/* <Input
              className="input_text_3 mr10"
              placeholder="Order Time"
              value={filter.OrderTime}
              onChange={handleFilterChange}
              name="OrderTime"
            /> */}
            <CustomDateTimePicker
              name="OrderTime"
              placeholder="Order Time"
              format="YYYY-MM-DD hh:mm A"
              value={filter.OrderTime}
              onChange={(value) => setFilter({ ...filter, OrderTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
        </Row>
      </div>
      <div className="flex justify-between my-6 overflow-x-auto">
        <div>
          <Button
            type="primary"
            className="mr-2 w-[104px] h-[32px] font-medium"
            onClick={handleFilter}
          >
            Apply
          </Button>
          <Button className="reset-button " onClick={handleReset}>
            Reset
          </Button>
        </div>
        <div>
          {loggedInUser.role_value !== 1 && loggedInUser.role_value !== 2 && (
            <>
              <Button
                type="primary"
                className="mr-2 w-[32px] h-[32px]"
                onClick={() => navigate("/order/add")}
              >
                <PlusOutlined />
              </Button>
              <Button
                type="primary"
                className="w-[32px] h-[32px]"
                disabled={selectedRowKeys?.length === 0}
                onClick={() => setShowModal(true)}
              >
                <DeleteOutlined />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
