import { ArrowLeftOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import useOemData from "../../../global/hooks/useOemData";
import WelcomeBar from "../../License/Components/WelcomeBar";
import { Button, Divider } from "antd";
import MacIcon from "../../../assets/images/vectors/MacIcon";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import SideCards from "../../License/Components/SideCards";
import { User } from "../../../global/components/User";
import { useLocation, useNavigate } from "react-router-dom";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import useGet from "../../../global/hooks/useGet";
import usePut from "../../../global/hooks/usePut";
import usePost from "../../../global/hooks/usePost";
import { LicenseContext, StepperContext } from "../Context/LicenseProvider";
import { showToast } from "../../../global/utils/toast";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import CheckIcon from "../../../assets/images/vectors/CheckIcon";
import { StepperComponent } from "../../../global/components/StepperComponent";

const UpgradedLicense = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { oemNo } = useOemData();
  const { setSelectedStep } = useContext(StepperContext);
  const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { email, mac, product } = customerInfo;
  const [mergedLicenseNames, setMergedLicenseNames] = useState();
  const [changedLicense, setChangedLicense] = useState();
  const [mergedLicense, setMergedLicense] = useState({});
  const [isApplied, setIsApplied] = useState();
  const { selectedLicense } = useContext(LicenseContext);

  const deviceUrl = `${process.env.REACT_APP_API_URL}/api/device/${mac}`;
  const usedLicenseUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/used`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;
  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(deviceUrl);

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(usedLicenseUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(deviceUrl);

  const handleApply = () => {
    const values = {
      Mac: mac,
      Product: mergedLicense.Product,
      Model: mergedLicense.Model,
      Type: mergedLicense.Type,
      Options: mergedLicense.Options,
      OemNo: deviceData?.data?.OemNo,
      Camera: mergedLicense.Camera,
      Channel: mergedLicense.Channel,
      Lockset: mergedLicense.Lockset,
      Facegate: mergedLicense.Facegate,
      Intercom: mergedLicense.Intercom,
      Sicucom: mergedLicense.Sicucom,
      Subnode: mergedLicense.Subnode,
      ContLock: mergedLicense.ContLock,
    };
    try {
      putAction(values);
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  // Function to merge licenses by finding the largest values and merging options
  const mergeLicenses = (licenses) => {
    const newLicense = {
      Product: Math.max(...licenses?.map((license) => license.Product)),
      Model: Math.max(...licenses?.map((license) => license.Model)),
      Type: Math.max(...licenses?.map((license) => license.Type)),
      OemNo: deviceData?.data?.OemNo,
      Camera: Math.max(...licenses?.map((license) => license.Camera)),
      Channel: Math.max(...licenses?.map((license) => license.Channel)),
      Lockset: Math.max(...licenses?.map((license) => license.Lockset)),
      Facegate: Math.max(...licenses?.map((license) => license.Facegate)),
      Subnode: Math.max(...licenses?.map((license) => license.Subnode)),
      ContLock: Math.max(...licenses?.map((license) => license.ContLock)),
      Intercom: Math.max(...licenses?.map((license) => license.Intercom)),
      Sicucom: Math.max(...licenses?.map((license) => license.Sicucom)),
      Options: [...new Set(licenses?.flatMap((license) => license.Options))],
    };

    return newLicense;
  };

  //function to compare which attributes are changed between old and new licenses
  const compareLicenses = (oldLicense, newLicense) => {
    return {
      product: oldLicense?.Product !== newLicense?.Product,
      model: oldLicense?.Model !== newLicense?.Model,
      type: oldLicense?.Type !== newLicense?.Type,
      oemNo: oldLicense?.OemNo !== newLicense?.OemNo,
      camera: oldLicense?.Camera !== newLicense?.Camera,
      channel: oldLicense?.Channel !== newLicense?.Channel,
      lockset: oldLicense?.Lockset !== newLicense?.Lockset,
      facegate: oldLicense?.Facegate !== newLicense?.Facegate,
      subnode: oldLicense?.Subnode !== newLicense?.Subnode,
      contLock: oldLicense?.ContLock !== newLicense?.ContLock,
      intercom: oldLicense?.Intercom !== newLicense?.Intercom,
      sicucom: oldLicense?.Sicucom !== newLicense?.Sicucom,
      options:
        JSON.stringify(oldLicense?.Options) !==
        JSON.stringify(newLicense?.Options),
    };
  };

  useEffect(() => {
    if (location.pathname.includes("newLicense")) {
      setSelectedStep(2);
    }
  }, []);

  useEffect(() => {
    if (isApplied) {
      setSelectedStep(3);
    }
  }, [isApplied]);

  useEffect(() => {
    const changedLicense = compareLicenses(deviceData?.data, mergedLicense);
    setChangedLicense(changedLicense);
  }, [mergedLicense, deviceData]);

  // useEffect to merge licenses when selectedLicense changes
  useEffect(() => {
    if (selectedLicense && deviceData && selectedLicense.length) {
      const licensesToCompare = [...selectedLicense, deviceData?.data];
      // console.log({ licensesToCompare });
      const newMergedLicense = mergeLicenses(licensesToCompare);
      setMergedLicense(newMergedLicense);
    }
  }, [selectedLicense, deviceData]);

  //send the order item no & mac
  useEffect(() => {
    if (putData && !putLoading && !putError) {
      const orderItemNumbers = selectedLicense
        .filter((item) => item?.ItemNo) // Filter out items that don't have OrderItemNo
        .map((item) => item.ItemNo); // Extract OrderItemNo

      const payload = {
        Mac: mac,
        ItemNo: orderItemNumbers,
      };
      postAction(payload);
    }
  }, [putData, putLoading]);

  //navigate to upgrade page after license is upgraded
  // useEffect(() => {
  //   if (postData && !postLoading && !postError) {
  //     sessionStorage.removeItem("customerInfo");
  //     navigate(`/upgrade?mac=${mac}&product=${product}`);
  //   }
  // }, [postData, postLoading]);

  useEffect(() => {
    if (putError) {
      showToast(putError?.response?.data?.message, "error", faWarning);
    } else if (!putLoading && !putError && putData) {
      // showToast(putData?.message, "success", faCheckCircle);
      setIsApplied(true);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    const product = optionData?.data?.products?.find(
      (item) => item.value === mergedLicense?.Product
    )?.name;
    const model = optionData?.data?.models?.find(
      (item) => item.value === mergedLicense?.Model
    )?.name;
    const options = optionData?.data?.options
      ?.filter((item) => mergedLicense?.Options?.includes(item.value))
      .map((item) => item.name);
    setMergedLicenseNames({ product, model, options });
  }, [mergedLicense, optionData]);

  return (
    <div className="min-h-screen bg-[#F2F2F2]">
      {/* TopBar */}
      <div className="flex justify-between items-center h-[55px] bg-white mb-2 border-b-[1px]">
        {/* <SicunetLogo className="ml-12" /> */}

        <div
          style={{
            height: "32px",
            width: "140px",
            cursor: "pointer",
            marginLeft: "15px",
          }}
        >
          <img
            src={logoPath}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="mr-4">
          <div className="ml-4">
            <User />
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      {/* <WelcomeBar /> */}

      {/* Summary Section */}
      <div className="flex flex-col justify-between px-3">
        {isApplied ? (
          <div className="w-[100%] bg-white">
            <div className="px-4 py-2">
              <StepperComponent />
            </div>
            <div className="  rounded-md flex flex-col justify-center items-center gap-4 h-[510px]">
              <CheckIcon />
              <p className="text-[#34B847] text-[20px] font-semibold">
                Your License Has Been Successfully Upgraded
              </p>
              <p className="text-center text-[#808080] text-[13px] font-semibold">
                {`Go to Jupiter, System > License > click ‘Get License Key’`}
              </p>
            </div>
            <SideCards />
          </div>
        ) : (
          <div className="w-[100%]">
            <div className="bg-white px-4 p-2 rounded-md mb-2">
              <StepperComponent />
              <div className="flex justify-between py-6 rounded-md">
                <Button
                  // type="primary"
                  className="px-4 py-1 rounded-md text-[#2270B8] bg-white hover:!text-[#2270B8] hover:!bg-white flex items-center !border-[#2270B8] !box-sizing-border !transition-none"
                  onClick={() => navigate("/orderedItems")}
                >
                  <span className="mr-1 mt-1">
                    <ArrowLeftOutlined />
                  </span>
                  <span className="text-[14px]">Previous</span>
                </Button>
                <Button
                  // type="primary"
                  className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!text-white hover:!bg-[#2270B8] flex items-center !border-none !box-sizing-border !transition-none"
                  onClick={handleApply}
                >
                  <span className="text-[14px]">Apply</span>
                  <span className="ml-1 mt-1">
                    <CheckCircleOutlined />
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-1/2 bg-white py-4 mr-2 rounded-md px-4">
                <p className="text-[#414141] text-[20px] text-center pt-2">
                  Old License
                </p>
                <Divider />
                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <MacIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        MAC:
                      </span>{" "}
                      {mac}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ProductIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Product:
                      </span>{" "}
                      {deviceData?.data?.ProductName}
                    </p>
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ModelIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Model:
                      </span>{" "}
                      {deviceData?.data?.ModelName}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <OemIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2 ">
                        OEM:
                      </span>{" "}
                      {deviceData?.data?.OemName}
                    </p>
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TypeIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Type:
                      </span>{" "}
                      {deviceData?.data?.Type}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <p className="text-sm p-2 flex items-center flex-wrap ">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl mb-1">
                      <OptionsIcon />
                    </span>
                    <span className="text-[#414141] font-light mx-2">
                      Options:
                    </span>{" "}
                    {deviceData?.data?.OptionNames_arr?.map((option) => {
                      return (
                        <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                          {option}
                        </span>
                      );
                    })}
                  </p>
                </div>

                <Divider className="my-2" />

                <div className="flex mb-9">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <CameraIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Camera:
                      </span>{" "}
                      {deviceData?.data?.Camera}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Lockset:
                      </span>{" "}
                      {deviceData?.data?.Lockset}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <SubnodeIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Subnode:
                      </span>{" "}
                      {deviceData?.data?.Subnode}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Intercom:
                      </span>{" "}
                      {deviceData?.data?.Intercom}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ChannelIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Channel:
                      </span>{" "}
                      {deviceData?.data?.Channel}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <FacegateIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Facegate:
                      </span>{" "}
                      {deviceData?.data?.Facegate}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-2">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Trilogy Lockset:
                      </span>{" "}
                      {deviceData?.data?.ContLock}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Sicunet Intercom:
                      </span>{" "}
                      {deviceData?.data?.Sicucom}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-1/2 bg-white rounded-md py-4 px-4">
                <p className="text-[#414141] text-[20px] text-center pt-2 relative">
                  New License{" "}
                  <span className="absolute top-0 ml-2 bg-[#34B847] text-white text-[10px] px-2 py-1 rounded-md">
                    Upgrade
                  </span>
                </p>
                <Divider />
                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <MacIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        MAC:
                      </span>{" "}
                      {mac}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ProductIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Product:
                      </span>{" "}
                      {mergedLicenseNames?.product}
                      {changedLicense?.product && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ModelIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Model:
                      </span>{" "}
                      {mergedLicenseNames?.model}
                      {changedLicense?.model && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <OemIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2 ">
                        OEM:
                      </span>{" "}
                      {deviceData?.data?.OemName}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TypeIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Type:
                      </span>{" "}
                      {mergedLicense?.Type}
                      {changedLicense?.type && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <p className="text-sm p-2 flex items-center flex-wrap">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl mb-1">
                      <OptionsIcon />
                    </span>
                    <span className="text-[#414141] font-light mx-2 mb-1">
                      Options:
                    </span>{" "}
                    {mergedLicenseNames?.options?.map((option) => {
                      return (
                        <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                          {option}
                        </span>
                      );
                    })}
                    {changedLicense?.options && (
                      <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                        New
                      </span>
                    )}
                  </p>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <CameraIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Camera:
                      </span>{" "}
                      {mergedLicense?.Camera}
                      {changedLicense?.camera && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Lockset:
                      </span>{" "}
                      {mergedLicense?.Lockset}
                      {changedLicense?.lockset && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <SubnodeIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Subnode:
                      </span>{" "}
                      {mergedLicense?.Subnode}
                      {changedLicense?.subnode && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Intercom:
                      </span>{" "}
                      {mergedLicense?.Intercom}
                      {changedLicense?.intercom && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <ChannelIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Channel:
                      </span>{" "}
                      {mergedLicense?.Channel}
                      {changedLicense?.channel && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <FacegateIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Facegate:
                      </span>{" "}
                      {mergedLicense?.Facegate}
                      {changedLicense?.facegate && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-2">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Trilogy Lockset:
                      </span>{" "}
                      {mergedLicense?.ContLock}
                      {changedLicense?.contlock && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-[#F4F5F9] p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-[#414141] font-light mx-2">
                        Sicunet Intercom:
                      </span>{" "}
                      {mergedLicense?.Sicucom}
                      {changedLicense?.sicucom && (
                        <span className="bg-[#34B847] ml-2 text-white text-[10px] py-0 px-1 rounded">
                          New
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* CARD SECTION STARTS HERE */}
      </div>
    </div>
  );
};

export default UpgradedLicense;
