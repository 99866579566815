import React from "react";
const IntercomIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#a)"
    >
      <path d="M13.334 1.333H2.667c-.737 0-1.333.597-1.333 1.334v10.666c0 .737.596 1.334 1.333 1.334h10.666c.737 0 1.334-.597 1.334-1.334V2.667c0-.737-.597-1.334-1.333-1.334ZM4 6v4m2.667-6v8m2.666-8v8M12 6v4" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default IntercomIcon;
