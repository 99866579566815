import React, { useContext, useState } from "react";
import { StepperContext } from "../../modules/UpgradeLicense/Context/LicenseProvider";

export const StepperComponent = () => {
  const { selectedStep } = useContext(StepperContext);
  console.log({ selectedStep });
  return (
    <div className="flex w-[100%] ">
      <div className="flex flex-col w-[33.33%]">
        <p className="text-[14px] text-[#929292] mb-1">1. License List</p>
        <div className="flex">
          <div className="h-[10px] w-[15%] bg-[#2270B8]"></div>
          <div
            className={`h-[10px] w-[83%] ${
              selectedStep == 2 || selectedStep == 3
                ? "bg-[#2270B8]"
                : "bg-[#E7E7E7]"
            } `}
          ></div>
          <div className="!bg-[#929292] w-[2%]"></div>
        </div>
      </div>

      <div className="flex flex-col w-[33.33%]">
        <p className="text-[14px] text-[#929292] mb-1">2. View Comparison</p>
        <div className="flex">
          <div
            className={`h-[10px] w-[15%] ${
              selectedStep === 2 || selectedStep === 3
                ? "bg-[#2270B8]"
                : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div
            className={`h-[10px] w-[83%] ${
              selectedStep === 3 ? "bg-[#2270B8]" : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div className="!bg-[#929292] w-[2%]"></div>
        </div>
      </div>
      <div className="flex flex-col w-[33.33%]">
        <p className="text-[14px] text-[#929292] mb-1">3. Confirmation</p>
        <div className="flex">
          <div
            className={`h-[10px] w-[15%] ${
              selectedStep === 3 ? "bg-[#2270B8]" : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div
            className={`h-[10px] w-[85%] ${
              selectedStep === 3 ? "bg-[#2270B8]" : "bg-[#E7E7E7]"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};
