import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../global/utils/toast";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { CloseOutlined } from "@ant-design/icons";
import SentIcon from "../../../assets/images/vectors/SentIcon";

export const InvitationForm = ({ setIsModalOpen }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [selectedOemNo, setSelectedOemNo] = useState();
  const [accountType, setAccountType] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const dealerOptionsUrl =
    selectedOemNo &&
    `${process.env.REACT_APP_API_URL}/api/getDealersByOem/${selectedOemNo}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/invite`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const getType = getModifiedResponse(optionsData, "roles");
  const modifiedType = getType.filter((item) => item.label !== "Customer");
  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedOems = getModifiedResponse(optionsData, "oems");

  const {
    data: dealerOp,
    loading: delaerOpLoading,
    error: dealerOpError,
    refetch: delaerOpRefetch,
  } = useGet(dealerOptionsUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const onFinish = (values) => {
    try {
      if (
        loggedInUser.role_name == "DealerAdmin" ||
        loggedInUser.role_name == "DealerUser"
      ) {
        const modifiedValues = {
          ...values,
          OemNo: loggedInUser.oem_no,
          DealerNo: loggedInUser.dealer_no,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      } else if (
        loggedInUser.role_name == "OemAdmin" ||
        loggedInUser.role_name == "OemUser"
      ) {
        const modifiedValues = {
          ...values,
          OemNo: loggedInUser.oem_no,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      } else {
        const modifiedValues = {
          ...values,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      }
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      showToast(postData?.message, "success", faCheckCircle);
      setIsModalOpen(false);
    }
  }, [postError, postData, postLoading]);

  return (
    <div>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            {
              <div className="mt-4 bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center">
                  <span className="text-[16px] text-[#222226] font-semibold ml-2">
                    Send Invitation
                  </span>
                </div>
                <div className="p-2">
                  <Row gutter={[16, 16]} className="mt-2">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Email"
                        name="Email"
                        type="email"
                        isEmail={true}
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Account Type"
                        name="AccountType"
                        options={modifiedType}
                        placeholder="Please select"
                        onChange={(value) => {
                          setAccountType(value);
                          form.setFieldsValue({
                            OemNo: "",
                            DealerNo: "",
                          });
                        }}
                        required
                      />
                    </Col>
                  </Row>
                  {(accountType === 6 ||
                    accountType === 4 ||
                    accountType === 5) &&
                    loggedInUser.role_name == "SuperAdmin" && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            onChange={(value) => {
                              setSelectedOemNo(value);
                              form.setFieldsValue({
                                DealerNo: "",
                              });
                            }}
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={
                              dealerOp?.data?.map((item) => ({
                                label: item.name,
                                value: item.value,
                              })) || []
                            }
                            placeholder="Please select"
                            required
                          />
                        </Col>
                      </Row>
                    )}
                  {(accountType === 2 || accountType === 3) &&
                    loggedInUser.role_name == "SuperAdmin" && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            required
                          />
                        </Col>
                      </Row>
                    )}
                  {(accountType === 6 ||
                    accountType === 4 ||
                    accountType === 5) &&
                    (loggedInUser.role_name == "OemAdmin" ||
                      loggedInUser.role_name == "OemUser") && (
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={modifiedDealer}
                            placeholder="Please select"
                            required
                          />
                        </Col>
                      </Row>
                    )}
                </div>
                <div className="flex justify-end">
                  <Button
                    className="mr-2 min-w-[104px] h-[32px] py-1 px-[10px] font-medium flex items-center !bg-white border !border-customColor !text-customColor hover:!bg-white hover:!text-customColor"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <CloseOutlined />
                    <span className="">Cancel</span>
                  </Button>
                  <Button
                    type="primary"
                    className="mr-2 min-w-[104px] h-[32px] py-1 px-[10px] font-medium flex items-center "
                    onClick={() => form.submit()}
                  >
                    <SentIcon />
                    <span className="">Send</span>
                  </Button>
                </div>
              </div>
            }
          </div>
        </Form>
      }
    </div>
  );
};
