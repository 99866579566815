import React from "react";
const FacegateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g fill="#8A8B91" clipPath="url(#a)">
      <path d="M8 .5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm4.596 12.096A6.477 6.477 0 1 1 3.45 3.423a6.477 6.477 0 0 1 9.146 9.173Z" />
      <path d="M4 6h1.25v1.25H4V6Zm6.5 0h1.25v1.25H10.5V6ZM7.25 9.592c0-.015.002-.03.006-.043L8.25 6.07v-.82h-1v.68l-.955 3.344a1.158 1.158 0 0 0 1.113 1.476H9.25v-1H7.408a.158.158 0 0 1-.158-.158Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FacegateIcon;
