import React from "react";
const OemInfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M2.406 2h8.469c.781 0 1.406.656 1.406 1.438v3.687c-.812.344-1.5.906-2 1.625-.062-.094-.219-.156-.344-.156H9a.46.46 0 0 0-.469.469V10c0 .281.219.5.469.5h.563a4.33 4.33 0 0 0-.094.938c0 1.906 1.156 3.562 2.812 4.312-.031.719-.656 1.313-1.406 1.313H8.062v-2.344a1.4 1.4 0 0 0-1.406-1.406c-.781 0-1.437.624-1.437 1.406v2.344H2.406A1.4 1.4 0 0 1 1 15.655V3.437C1 2.657 1.625 2 2.406 2Zm.469 7.063V10c0 .281.219.5.469.5h.937c.282 0 .469-.219.469-.5v-.938a.46.46 0 0 0-.469-.468h-.937a.48.48 0 0 0-.469.469Zm3.313-.47c-.282 0-.5.22-.5.47V10c0 .281.218.5.5.5h.937c.25 0 .469-.219.469-.5v-.938a.48.48 0 0 0-.469-.468h-.938Zm-2.844-3.75a.48.48 0 0 0-.469.47v.937c0 .25.219.469.469.469h.937a.46.46 0 0 0 .469-.469v-.938a.46.46 0 0 0-.469-.468h-.937Zm2.344.47v.937c0 .25.218.469.5.469h.937a.48.48 0 0 0 .469-.469v-.938a.48.48 0 0 0-.469-.468h-.938c-.28 0-.5.218-.5.468ZM9 4.843a.46.46 0 0 0-.469.47v.937c0 .25.219.469.469.469h.938a.48.48 0 0 0 .468-.469v-.938a.48.48 0 0 0-.469-.468H9Zm5.188 4.22c-.844 0-1.626.468-2.063 1.187a2.406 2.406 0 0 0 0 2.344 2.374 2.374 0 0 0 2.063 1.187c.812 0 1.593-.437 2.03-1.187a2.406 2.406 0 0 0 0-2.344c-.437-.719-1.218-1.188-2.03-1.188Zm0 6.124h-.032a3.761 3.761 0 0 1-3.75-3.75c0-2.093 1.688-3.78 3.75-3.78a3.774 3.774 0 0 1 3.781 3.78c0 .782-.25 1.5-.656 2.094l2.344 2.344a.76.76 0 0 1 0 1c-.281.281-.75.281-1 0l-2.344-2.344c-.593.438-1.312.656-2.093.656Z"
    />
  </svg>
);
export default OemInfoIcon;
