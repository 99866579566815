import React from "react";
const AccountIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M6 2h5v4c0 .563.438 1 1 1h4v9c0 1.125-.906 2-2 2H6c-1.125 0-2-.875-2-2V4c0-1.094.875-2 2-2Zm6 0 4 4h-4V2Zm-4 8c0 .719.375 1.375 1 1.75.594.344 1.375.344 2 0 .594-.375 1-1.031 1-1.75 0-.688-.406-1.344-1-1.719-.625-.344-1.406-.344-2 0A2.02 2.02 0 0 0 8 10Zm-1.5 5.5c0 .281.219.5.5.5h6c.25 0 .5-.219.5-.5 0-1.375-1.125-2.5-2.5-2.5H9a2.487 2.487 0 0 0-2.5 2.5Z"
    />
  </svg>
);
export default AccountIcon;
