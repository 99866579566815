import React from "react";
const LicenseOpIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M3.518 3.333h12.963c1.013 0 1.852.84 1.852 1.852v.926H1.667v-.926c0-1.013.81-1.852 1.851-1.852ZM18.333 8.89v5.556c0 1.041-.839 1.851-1.852 1.851H3.518a1.828 1.828 0 0 1-1.851-1.851V8.889h16.666ZM4.907 12.593a.476.476 0 0 0-.463.463c0 .26.203.463.463.463H6.76a.476.476 0 0 0 .463-.463.498.498 0 0 0-.463-.463H4.907Zm3.241.463c0 .26.203.463.463.463h3.704a.476.476 0 0 0 .463-.463.498.498 0 0 0-.463-.463H8.61a.476.476 0 0 0-.463.463Z"
    />
  </svg>
);
export default LicenseOpIcon;
