import React, { useEffect, useState } from "react";
import useOemData from "../../../global/hooks/useOemData";
import { User } from "../../../global/components/User";
import { Button, Divider, Modal } from "antd";
import { ArrowRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import SideCards from "../Components/SideCards";
import WelcomeBar from "../Components/WelcomeBar";
import MacIcon from "../../../assets/images/vectors/MacIcon";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import usePost from "../../../global/hooks/usePost";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import { useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import CheckIcon from "../../../assets/images/vectors/CheckIcon";

const InitialLicense = ({ type }) => {
  const navigate = useNavigate();
  const { oemNo } = useOemData();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { email, mac } = customerInfo;
  const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);

  const initialLicenseUrl = `${process.env.REACT_APP_API_URL}/api/initial/info`;
  const deviceUrl =
    type == "upgrade" && `${process.env.REACT_APP_API_URL}/api/device/${mac}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/device/create`;
  const [isApplied, setIsApplied] = useState();

  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(deviceUrl);

  const {
    data: initialLicenseData,
    loading: initialLicenseLoading,
    error: initialLicenseError,
    postData: initialLicenseAction,
  } = usePost(initialLicenseUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  //User guide modal content
  const tabItems = [
    {
      key: 1,
      text: (
        <div className="text-center mt-4">
          <p className="font-medium text-[20px] ">
            Welcome to License Server!{" "}
          </p>
          <p className="text-[14px] text-[#606060] mt-2 mb-6">
            This guide will walk you through the steps. <br /> At first, You can
            see your current license details
          </p>
        </div>
      ),
    },
    {
      key: 2,
      text: (
        <div className="text-center mt-8">
          <p className="text-[14px] text-[#606060] mb-6">
            Choose the licenses you want to upgrade and you can proceed to the
            next step
          </p>
        </div>
      ),
    },
    {
      key: 3,
      text: (
        <div className="text-center mt-8">
          <p className="text-[14px] text-[#606060] mb-6">
            Review the comparison and click{" "}
            <span className="font-medium">"Apply"</span> to confirm the upgrade.
          </p>
        </div>
      ),
    },
  ];

  const handleApply = () => {
    const values = {
      Mac: mac,
      Product: initialLicenseData?.data[0].Product,
      Model: initialLicenseData?.data[0].Model,
      Type: initialLicenseData?.data[0].Type,
      Options: initialLicenseData?.data[0].Options,
      OemNo: initialLicenseData?.data[0].OemNo,
      Camera: initialLicenseData?.data[0].Camera,
      Channel: initialLicenseData?.data[0].Channel,
      Lockset: initialLicenseData?.data[0].Lockset,
      Facegate: initialLicenseData?.data[0].Facegate,
      Intercom: initialLicenseData?.data[0].Intercom,
      Subnode: initialLicenseData?.data[0].Subnode,
      ContLock: initialLicenseData?.data[0].ContLock,
    };
    try {
      postAction(values);
    } catch (error) {
      showToast("Something went wrong, Please try again!", "error", faWarning);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleNext = () => {
    if (currentTabIndex < tabItems.length - 1) {
      setCurrentTabIndex(currentTabIndex + 1);
    } else {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (email && type == "initial") {
      initialLicenseAction({ email });
    }
  }, []);

  //toast for create
  useEffect(() => {
    if (postError) {
      showToast(postError?.response?.data?.message, "error", faWarning);
    } else if (!postLoading && !postError && postData) {
      // showToast(postData?.message, "success", faCheckCircle);
      setIsApplied(true);
    }
  }, [postError, postData, postLoading]);

  return (
    <div className="min-h-screen bg-[#F2F2F2] ">
      {/* TopBar */}
      <div className="flex justify-between items-center h-[55px] bg-white pl-4 mb-2 border-b-[1px]">
        {/* <SicunetLogo className="ml-12" /> */}

        <div
          style={{
            height: "32px",
            width: "140px",
            cursor: "pointer",
          }}
        >
          <img
            src={logoPath}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="mr-4">
          <div className="ml-4">
            <User />
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      {/* <WelcomeBar /> */}

      {/* Summary Section */}
      <div className="px-3">
        {isApplied ? (
          <div className="w-[100%] bg-white rounded-sm flex flex-col justify-center items-center gap-4">
            <CheckIcon />
            <p className="text-[#34B847] text-[20px] font-semibold">
              Your License Has Been Created Successfully
            </p>
            <p className="text-center text-[#808080] text-[13px] font-semibold">
              {`To retrieve your license key, go to Jupiter, navigate to System > License > Edit, and click Get License Key`}
            </p>
            <p className="text-center text-[#808080] text-[13px] font-semibold">
              {" "}
              Once retrieved, click Apply to update your license and see the
              changes
            </p>
          </div>
        ) : (
          <>
            <div className="w-[100%] rounded-sm my-4">
              <div className="flex justify-between items-center rounded-md">
                <p className="text-[#121212] text-[16px]">
                  Current License Information
                </p>
                <div className="flex space-x-2">
                  {type === "initial" && (
                    <Button
                      // type="primary"
                      className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!border-none hover:!bg-[#2270B8] flex items-center 
             !border-none !box-sizing-border !transition-none"
                      onClick={handleApply}
                    >
                      <span className="text-[14px]">Apply</span>
                      <span id="arrowIcon" className="ml-1 mt-1">
                        <CheckCircleOutlined />
                      </span>
                    </Button>
                  )}
                  {type === "upgrade" && (
                    <Button
                      // type="primary"
                      className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!text-white hover:!border-none hover:!bg-[#2270B8] flex items-center 
             !border-none !box-sizing-border !transition-none"
                      onClick={() => navigate("/orderedItems")}
                    >
                      <span className="text-[14px]">Upgrade License</span>
                      <span id="arrowIcon" className="ml-1">
                        <ArrowRightOutlined />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
              <div className="text-[14px] font-semibold">Device</div>
              <Divider className="my-3" />

              <div className="flex">
                <p className="text-sm flex items-center py-1">
                  <span className="bg-[#F4F5F9] p-2 rounded-xl">
                    <MacIcon />
                  </span>
                  <span className="text-[#121212] font-light mx-2">MAC:</span>{" "}
                  {mac}
                </p>
              </div>
            </div>

            <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
              <div className="text-[14px] font-semibold">
                License Information
              </div>
              <Divider className="my-3" />

              <div className="flex">
                <div className="w-1/2">
                  <p className="text-sm py-1 flex items-center">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <ProductIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Product:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].ProductName
                      : deviceData?.data.ProductName}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <ModelIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Model:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].ModelName
                      : deviceData?.data.ModelName}
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="text-sm py-1 flex items-center">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <TypeIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Type:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Type
                      : deviceData?.data?.Type}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <OemIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2 ">
                      OEM:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].OemName
                      : deviceData?.data?.OemName}
                  </p>
                </div>
              </div>
            </div>

            <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
              <div className="text-[14px] font-semibold">License Options</div>
              <Divider className="my-3" />
              <div>
                <p className="text-sm p-2 flex items-center">
                  <span className="bg-[#F4F5F9] p-2 rounded-xl">
                    <OptionsIcon />
                  </span>
                  <span className="text-[#121212] font-light mx-2">
                    Options:
                  </span>{" "}
                  {type == "initial"
                    ? initialLicenseData?.data[0].OptionNames?.map((option) => {
                        return (
                          <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1 ">
                            {option}
                          </span>
                        );
                      })
                    : deviceData?.data?.OptionNames_arr?.map((option) => {
                        return (
                          <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                            {option}
                          </span>
                        );
                      })}
                </p>
              </div>
            </div>

            <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
              <div className="text-[14px] font-semibold">License Devices</div>
              <Divider className="my-3" />
              <div className="flex">
                <div className="w-1/2">
                  <p className="text-sm py-1 flex items-center">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <CameraIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Camera:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Camera
                      : deviceData?.data?.Camera}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <TrilogyIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Lockset:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Lockset
                      : deviceData?.data?.Lockset}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <SubnodeIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Subnode:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Subnode
                      : deviceData?.data?.Subnode}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <IntercomIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Intercom:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Intercom
                      : deviceData?.data?.Intercom}
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="text-sm py-1 flex items-center">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <ChannelIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Channel:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Channel
                      : deviceData?.data?.Channel}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <FacegateIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Facegate:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Facegate
                      : deviceData?.data?.Facegate}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <TrilogyIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Trilogy Lockset:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].ContLock
                      : deviceData?.data?.ContLock}
                  </p>
                  <p className="text-sm py-1 flex items-center mt-2">
                    <span className="bg-[#F4F5F9] p-2 rounded-xl">
                      <IntercomIcon />
                    </span>
                    <span className="text-[#121212] font-light mx-2">
                      Sicunet Intercom:
                    </span>{" "}
                    {type == "initial"
                      ? initialLicenseData?.data[0].Sicucom
                      : deviceData?.data?.Sicucom}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {/* CARD SECTION STARTS HERE */}
        {/* <SideCards /> */}

        {/* {type === "upgrade" && (
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            centered
            okText={currentTabIndex === tabItems.length - 1 ? "Done" : "Next"}
            cancelText="Skip"
            onOk={handleNext}
          >
            {tabItems[currentTabIndex].text}
          </Modal>
        )} */}
      </div>
    </div>
  );
};

export default InitialLicense;
