import React from "react";
const OptionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.333 14.667h-.182c-2.173 0-3.261 0-4.016-.532a2.734 2.734 0 0 1-.57-.537C2 12.888 2 11.865 2 9.818V8.121c0-1.975 0-2.963.313-3.752.502-1.268 1.565-2.268 2.913-2.742.837-.294 1.886-.294 3.986-.294 1.199 0 1.799 0 2.277.168.77.271 1.378.842 1.665 1.567.18.45.18 1.015.18 2.144v2.121"
    />
    <path
      stroke="#8A8B91"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2 8a2.222 2.222 0 0 1 2.222-2.222c.444 0 .967.077 1.399-.038a1.113 1.113 0 0 0 .786-.787c.115-.431.038-.954.038-1.398a2.222 2.222 0 0 1 2.222-2.222M11 8.667c-.95 0-1.567.606-2.295.826-.296.09-.444.135-.504.198-.06.064-.077.156-.112.34-.376 1.98.445 3.809 2.403 4.52.21.078.315.116.509.116.193 0 .298-.038.508-.115 1.958-.712 2.778-2.541 2.402-4.52-.035-.185-.053-.278-.112-.34-.06-.063-.208-.11-.504-.199-.729-.22-1.345-.826-2.295-.826Z"
    />
  </svg>
);
export default OptionsIcon;
