import React from "react";
const ModelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#8A8B91"
      d="M12.916 5.667a2.166 2.166 0 1 1-2.108 2.666h-2a2.168 2.168 0 0 1-2.962 1.673L4.809 11.84a2.167 2.167 0 1 1-.895-.45L5.03 9.42A2.16 2.16 0 0 1 4.5 8c0-.605.248-1.151.647-1.544L4.078 4.564a2.169 2.169 0 0 1-2.571-3.088 2.167 2.167 0 1 1 3.427 2.57l1.068 1.891a2.167 2.167 0 0 1 2.726 1.396h2.08a2.167 2.167 0 0 1 2.108-1.666Zm-6.25 1.166a1.167 1.167 0 1 0-.056 2.333 1.167 1.167 0 0 0 .056-2.333Zm-3.25-5.5a1.167 1.167 0 1 0 0 2.334 1.167 1.167 0 0 0 0-2.334Zm8.334 6.5a1.167 1.167 0 1 0 2.333 0 1.167 1.167 0 0 0-2.333 0ZM2.25 13.5a1.167 1.167 0 1 0 2.334 0 1.167 1.167 0 0 0-2.334 0Z"
    />
  </svg>
);
export default ModelIcon;
