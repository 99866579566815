import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { showToast } from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { OrderItemColumn } from "./Column";

import { OrderItemFilter } from "../Components/OrderItemFilter";
import { convertToUnix } from "../../../global/utils/unixToLocalTime";
import { Modal } from "antd";
import { OrderItemForm } from "../Components/OrderItemForm";
// import { OrderItemColumns } from "../Components/FilterComponent";

const initialFilterState = {
  ItemNo: "",
  OemNo: "",
  DealerNo: "",
  AccountName: "",
  OrderName: "",
  Paid: "",
  OrderTime: "",
  CustomerName: "",
  LicenseName: "",
  Used: "",
  UsedMac: "",
  UsedTime: "",
};

export const OrderItem = () => {
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const [id, setId] = useState();
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "ItemNo",
    order: "desc",
  });

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "Order Item",
      href: "/orderitemlist",
    },
  ];

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/orderitem/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/orderitem/all`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitems/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/orderiteminfo/${record?.ItemNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        if (key === "UsedTime" || key === "OrderTime") {
          filterDict[key] = convertToUnix(filter[key]);
        } else {
          filterDict[key] = filter[key];
        }
        // filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "ItemNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      showToast("Failed!", "error", faWarning);
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      showToast("Successfully Deleted!", "success", faCheckCircle);
      refetch();
    } else if (deletedDataError) {
      showToast("Failed!", "error", faWarning);
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.ItemNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  // console.log({ listData });

  return loading ? (
    <div className="w-full flex items-center justify-center h-screen">
      <Spinner />
    </div>
  ) : (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-2 bg-white rounded-[4px] border border-gray-200">
        <div className="p-2">
          <OrderItemFilter
            filter={filter}
            handleFilterChange={handleFilterChange}
            handleFilter={handleFilter}
            handleReset={handleReset}
            setShowModal={setShowModal}
            selectedRowKeys={selectedRowKeys}
            setFilter={setFilter}
          />
          <ConfirmModal
            showModal={showModal}
            setShowModal={setShowModal}
            onDeleteConfirm={onDeleteConfirm}
          />
          <Modal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
            width={1000}
            centered
          >
            <OrderItemForm id={id} />
          </Modal>
          <GlobalTable
            columns={OrderItemColumn(setParams, params, setIsModalOpen, setId)}
            dataSource={listData}
            // rowSelection={rowSelection}
            pagination={paginationConfig}
            // onRow={onRow}
          />
        </div>
      </div>
    </div>
  );
};
