import React from "react";
const OemIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#3A3A41"}
      d="M2.313 2.75h7.874c.711 0 1.313.602 1.313 1.313V4.5h-.219c-.765 0-1.449.355-1.886.902-.028 0-.055-.027-.082-.027h-.876A.45.45 0 0 0 8 5.813v.875c0 .246.191.437.438.437h.437v1.75h-.438A.45.45 0 0 0 8 9.313v.874c0 .247.191.438.438.438h.437v4.594c0 .601.191 1.12.547 1.531h-1.86v-2.188A1.33 1.33 0 0 0 6.25 13.25c-.738 0-1.313.602-1.313 1.313v2.187H2.313A1.296 1.296 0 0 1 1 15.437V4.063c0-.711.574-1.313 1.313-1.313Zm.437 6.563v.874c0 .247.191.438.438.438h.874a.45.45 0 0 0 .438-.438v-.874a.47.47 0 0 0-.438-.438h-.875a.45.45 0 0 0-.437.438Zm3.063-.438a.45.45 0 0 0-.438.438v.874c0 .247.191.438.438.438h.875a.45.45 0 0 0 .437-.438v-.874a.47.47 0 0 0-.438-.438h-.875Zm-2.625-3.5a.45.45 0 0 0-.438.438v.875c0 .246.191.437.438.437h.874a.45.45 0 0 0 .438-.438v-.875a.47.47 0 0 0-.438-.437h-.875Zm2.187.438v.875c0 .246.191.437.438.437h.875a.45.45 0 0 0 .437-.438v-.875a.47.47 0 0 0-.438-.437h-.875a.45.45 0 0 0-.437.438Zm6.125-.438h5.25c.957 0 1.75.793 1.75 1.75V15c0 .984-.793 1.75-1.75 1.75H11.5c-.984 0-1.75-.766-1.75-1.75V7.125c0-.957.766-1.75 1.75-1.75Zm.438 2.406c-.383 0-.657.301-.657.657 0 .382.274.656.656.656a.648.648 0 0 0 .657-.656c0-.356-.301-.657-.656-.657Zm-.657 3.282c0 .382.274.656.656.656a.648.648 0 0 0 .657-.656c0-.356-.301-.657-.656-.657-.383 0-.657.301-.657.656Zm.656 1.968c-.382 0-.656.301-.656.656 0 .383.274.657.656.657a.648.648 0 0 0 .657-.656c0-.356-.301-.657-.656-.657Zm1.75-4.594c0 .247.192.438.438.438h2.625a.45.45 0 0 0 .438-.438A.47.47 0 0 0 16.75 8h-2.625a.45.45 0 0 0-.438.438Zm.438 2.188a.45.45 0 0 0-.438.438c0 .246.192.437.438.437h2.625a.45.45 0 0 0 .438-.438.47.47 0 0 0-.438-.437h-2.625Zm-.438 3.063c0 .246.192.437.438.437h2.625a.45.45 0 0 0 .438-.438.47.47 0 0 0-.438-.437h-2.625a.45.45 0 0 0-.438.438Z"
    />
  </svg>
);
export default OemIcon;
